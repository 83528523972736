import {
  Divider,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { Person, VerticalAlignBottom } from "@material-ui/icons";

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  collectionActions,
  getProduitTransformeReference,
  loadCollectionAttribute,
} from "../../../reducers/collectionsReducer";

import CollectionCrud from "../../common/Components/CollectionCrud";
import DateInput from "../../common/Components/DateInput";
import TextInput from "../../common/Components/TextInput";
import UserSelect from "../../common/Components/UserSelect";
import { hasRights } from "../../common/Tools/Tools";

import { themeComplement } from "../AppAdmin/css/theme";
import { addNotification } from "../../../reducers/notificationReducer";
import { getStatuts, getUsers } from "./actions/SilolistActions";
import SilolistCss from "./css/SilolistCss";
import SelectProduitTransforme from "../Packinglist/SelectProduitTransforme";
import ProduitTransforme from "../Stock/ProduitTransforme";
import { REF_PREFIX as ORIGINES_PREFIX } from "../ProduitsOrigines";
import { getFromLocalStorage, storeToLocalStorage } from "../../tools/Tools";

const POIDS_ENTREE_MAX = 13000; // 13 tonnes

const REF_PREFIX = "SL";

const STATUT = {
  INITIE: "initie",
  A_SILOTER: "a_siloter",
  SILOTAGE_EN_COURS: "silotage_en_cours",
  OT_FINI: "ot_fini",
};

const LOCAL_STORAGE_KEY = "SILOLISTS_FILTERS";

class Silolist extends Component {
  state = {
    famille_uuid: null,
    statuts: null,
    produits_entree: [],
    produits_sortie: [],
    produits_entree_sesotec: [],
    silolist: {},
    users: [],
    lastProduitSortie: null,
    current_sl_uuid: null,
    filter_status: [STATUT.SILOTAGE_EN_COURS, STATUT.INITIE, STATUT.A_SILOTER],
    local_storage_key: LOCAL_STORAGE_KEY,
    current_filter: {},
  };

  componentDidMount() {
    getStatuts((statuts) => {
      this.setState({ statuts });
    });
    getUsers().then((users) => {
      if (users.length > 0) {
        this.setState({ users });
      }
    });
    collectionActions(this.props.dispatch, "silolists", "RESET_LIST");

    const { local_storage_key } = this.props;
    if (local_storage_key) {
      this.setState({ local_storage_key: local_storage_key });
    }
    let filter_localStorage = getFromLocalStorage(
      local_storage_key ? local_storage_key : LOCAL_STORAGE_KEY
    );
    if (filter_localStorage) {
      this.setState(filter_localStorage, () => {
        this.loadAsyncData(this.state.current_filter, true);
      });
    } else {
      this.loadAsyncData(this.state.current_filter, true);
    }
  }

  showBtnDelete(silolist) {
    switch (silolist.statut) {
      case STATUT.INITIE:
      case STATUT.A_SILOTER:
        return this.hasRight("admin-silolists-creation");
      case STATUT.SILOTAGE_EN_COURS:
      case STATUT.OT_FINI:
        return this.isAdminOrSuperAdmin();
      default:
        return false;
    }
  }

  compare(a, b) {
    const refA = a.reference.toUpperCase();
    const refB = b.reference.toUpperCase();
    if (refA > refB) {
      return 1;
    }
    if (refA < refB) {
      return -1;
    }
    return 0;
  }

  getArticles(famille_uuid) {
    if (!this.props.articlesStore.list) {
      return [];
    }

    let list = this.props.articlesStore.list.slice();

    if (famille_uuid) {
      list = list.filter((a) => a.famille_uuid === famille_uuid);
    }
    return list.map((article) => {
      return (
        <MenuItem value={article.uuid} key={article.uuid}>
          {article.nom}
        </MenuItem>
      );
    });
  }

  getCellsConfig() {
    return [
      {
        datakey: "reference",
        title: "Référence",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "user_uuids",
        title: "Commerciaux",
        sortable: true,
        searchable: true,
        format: (silolist) => this.getUserLead(silolist),
      },
      {
        datakey: "article.famille_nom",
        title: "Famille",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "article.nom",
        title: "Article",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "statut",
        title: "Statut",
        sortable: true,
        searchable: true,
        format: (silolist) =>
          this.state.statuts[
            Object.keys(this.state.statuts).find(
              (statut) => statut === silolist.statut
            )
          ],
      },
      {
        datakey: "sesotec_objectif",
        title: "Objectif",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "sesotec_minimum",
        title: "Minimum",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "date_attendue",
        title: "Date attendue",
        sortable: true,
        searchable: true,
        isDate: true,
        format: (obj, key) => {
          return this.getDate(obj[key]);
        },
      },
      {
        datakey: "nb_bb_entree",
        title: "Entrée nb BB",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "poids_bb_entree",
        title: "Entrée poids",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "date_fin",
        title: "Date réalisée",
        sortable: true,
        searchable: true,
        isDate: true,
        format: (obj, key) => {
          return this.getDate(obj[key]);
        },
      },
      {
        datakey: "nb_bb_sortie",
        title: "Sortie nb BB",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "poids_bb_sortie",
        title: "Sortie poids",
        sortable: true,
        searchable: true,
      },
      {
        datakey: "observations",
        title: "Observations",
        sortable: true,
        searchable: true,
      },
    ];
  }

  getCreateUpdateModalTitle(silolist) {
    let title = !!silolist
      ? "Modification de la silo list : " + silolist.reference
      : "Ajout d'une nouvelle silo list";
    return (
      <div>
        <Person />
        <span>{title}</span>
      </div>
    );
  }

  onChangeHandlerSiloList(name, event) {
    this.setState({
      silolist: {
        ...this.state.silolist,
        [name]: event.target.value,
      },
    });
  }

  getCreateUpdateModalContent(silolist, silolistsStore, onChangeHandler) {
    const { classes } = this.props;
    let {
      famille_uuid,
      produits_entree,
      produits_sortie,
      users,
      lastProduitSortie,
      current_sl_uuid,
    } = this.state;

    let {
      article_uuid,
      date_attendue,
      date_debut,
      date_fin,
      observations,
      statut,
      user_uuids,
      uuid,
      sesotec_objectif,
      sesotec_minimum,
    } = this.state.silolist;

    if (current_sl_uuid !== uuid) {
      this.setState({
        current_sl_uuid: uuid,
        lastProduitSortie: null,
      });
    }

    if (!produits_entree || !produits_sortie) return null;

    const onChangeHandlerSiloList = this.onChangeHandlerSiloList.bind(this);

    const filterProduitsSortie = (refPattern) =>
      produits_sortie.filter((item) => refPattern.test(item.reference));
    const produitsComplet = filterProduitsSortie(
      new RegExp(`^${ORIGINES_PREFIX.SILO}-`)
    );
    const produitsIncomplet = filterProduitsSortie(
      new RegExp(`^${ORIGINES_PREFIX.SILO_INCOMPLET}-`)
    );
    const countComplet = produitsComplet.length;
    const countIncomplet = produitsIncomplet.length;
    const sumPoids = (cumulated, item) => cumulated + parseInt(item.poids);
    const sumPoidsComplet = produitsComplet.reduce(sumPoids, 0);
    const sumPoidsIncomplet = produitsIncomplet.reduce(sumPoids, 0);
    const sumPoidsEntree = produits_entree.reduce(sumPoids, 0);
    const sumPoidsSortie = produits_sortie.reduce(sumPoids, 0);
    const deltaPoids = sumPoidsSortie - sumPoidsEntree;
    const deltaFormatter = new Intl.NumberFormat("fr-FR", {
      signDisplay: "exceptZero",
    });
    const percentPoids = deltaPoids / sumPoidsEntree;
    const percentFormatter = new Intl.NumberFormat("fr-FR", {
      signDisplay: "exceptZero",
      style: "percent",
    });

    const statutInitial = silolistsStore.detail
      ? silolistsStore.detail.statut
      : null;
    const authorizeCreation = () => {
      switch (statutInitial) {
        case null: // nouvelle silolist
        case STATUT.INITIE:
        case STATUT.A_SILOTER:
          return this.hasRight("admin-silolists-creation");
        case STATUT.SILOTAGE_EN_COURS:
        case STATUT.OT_FINI:
        default:
          return this.isAdminOrSuperAdmin();
      }
    };
    const authorisedateFin = () => {
      return (
        this.isAdminOrSuperAdmin() ||
        (statutInitial === STATUT.SILOTAGE_EN_COURS &&
          this.hasRight("admin-silolists-production"))
      );
    };
    const authorizeObservations = () => {
      return this.isAdminOrSuperAdmin() || statutInitial !== STATUT.OT_FINI;
    };

    // Ici, on se base sur statut et non statutInitial car on souhaite que l'utilisateur
    // puisse directement créer des produits en sortie sans devoir valider (et donc fermer
    //  la fenêtre et devoir la rouvrir à nouveau)
    const authorizeProduction = () => {
      switch (statut) {
        case STATUT.INITIE:
        case STATUT.A_SILOTER:
          return this.isAdminOrSuperAdmin();
        case STATUT.SILOTAGE_EN_COURS:
          return this.hasRight("admin-silolists-production");
        case STATUT.OT_FINI:
        default:
          return this.isAdminOrSuperAdmin();
      }
    };

    return (
      <Grid container>
        <Grid item xs={3}>
          <TextInput
            id="statut"
            label="Statut"
            value={statut}
            required
            margin="normal"
            collectionStore={silolistsStore}
            name="statut"
            onChangeHandler={onChangeHandlerSiloList}
            select
            fullWidth
          >
            {this.getStatutsList()}
          </TextInput>
        </Grid>
        <Grid item xs={3}>
          <DateInput
            id="date_attendue"
            label="Date de réalisation attendue"
            value={date_attendue}
            disabled={!authorizeCreation()}
            margin="normal"
            collectionStore={silolistsStore}
            name="date_attendue"
            onChangeHandler={onChangeHandlerSiloList}
            fullWidth
            pickertype="date"
            format={"d MMMM yyyy"}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="famille_uuid"
            label="Famille principale en sortie *"
            value={famille_uuid}
            disabled={!authorizeCreation()}
            margin="normal"
            collectionStore={silolistsStore}
            name="famille_uuid"
            onChangeHandler={this.onArticleChangeHandler.bind(this)}
            select
            fullWidth
          >
            {this.getFamilles()}
          </TextInput>
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="article_uuid"
            label="Article principal en sortie"
            value={article_uuid}
            disabled={!authorizeCreation() || !famille_uuid}
            margin="normal"
            collectionStore={silolistsStore}
            name="article_uuid"
            onChangeHandler={onChangeHandlerSiloList}
            required
            fullWidth
            select
          >
            {this.getArticles(famille_uuid)}
          </TextInput>
        </Grid>
        <Grid item xs={6}>
          <UserSelect
            collectionStore={silolistsStore}
            disabled={!authorizeCreation()}
            label="Commerciaux"
            name="user_uuids"
            onChange={this.handleChangeUser.bind(this)}
            onDelete={this.handleDeleteUser.bind(this)}
            required
            selected={user_uuids ? user_uuids : []}
            users={users}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            id="observations"
            label="Observations"
            value={observations}
            disabled={!authorizeObservations()}
            margin="normal"
            collectionStore={silolistsStore}
            name="observations"
            onChangeHandler={onChangeHandlerSiloList}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <DateInput
            id="date_debut"
            label="Date de démarrage"
            value={date_debut}
            disabled={!authorizeProduction()}
            margin="normal"
            collectionStore={silolistsStore}
            name="date_debut"
            onChangeHandler={onChangeHandlerSiloList}
            fullWidth
            pickertype="datetime"
            format={"d MMMM yyyy à HH:mm"}
          />
        </Grid>
        <Grid item xs={3}>
          <DateInput
            id="date_fin"
            label="Date d'achèvement"
            value={date_fin}
            disabled={!authorisedateFin()}
            margin="normal"
            collectionStore={silolistsStore}
            name="date_fin"
            onChangeHandler={onChangeHandlerSiloList}
            fullWidth
            pickertype="datetime"
            format={"d MMMM yyyy à HH:mm"}
          />
        </Grid>
        <Grid container xs={6} className={this.props.classes.sesotec}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={this.props.classes.title}
            xs={2}
          >
            Sesotec
          </Typography>

          <Grid item xs={5}>
            <TextInput
              id="sesotec_objectif"
              label="objectif"
              value={sesotec_objectif}
              disabled={!authorizeCreation()}
              margin="normal"
              collectionStore={silolistsStore}
              name="sesotec_objectif"
              onChangeHandler={onChangeHandlerSiloList}
              select
              fullWidth
            >
              {this.getSesotecOptions()}
            </TextInput>
          </Grid>
          <Grid item xs={5}>
            <TextInput
              id="sesotec_minimum"
              label="Minimum"
              value={sesotec_minimum}
              disabled={!authorizeCreation()}
              margin="normal"
              collectionStore={silolistsStore}
              name="sesotec_minimum"
              onChangeHandler={onChangeHandlerSiloList}
              select
              fullWidth
            >
              {this.getSesotecOptions()}
            </TextInput>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.divider}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.title}
          >
            Répartition par Famille / Article
          </Typography>
          {this.getDetailPerFamille()}
        </Grid>
        <Grid item xs={6} className={classes.divider}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.title}
          >
            Répartition Pir/Pcr
          </Typography>
          {this.getDetailPirPcr()}
        </Grid>
        <Grid item xs={12} className={classes.divider}>
          <Divider />
        </Grid>
        <SelectProduitTransforme
          title="Produits en entrée"
          disabled={!authorizeCreation()}
          selectCb={this.onClickSelectProduitHandler.bind(this)}
          removeCb={this.onClickRemoveProduitHandler.bind(this)}
          selection={this.state.produits_entree}
          silolist_uuid={
            this.props.silolistsStore.detail
              ? this.props.silolistsStore.detail.uuid
              : ""
          }
          detail_per_famille={
            this.props.silolistsStore.detail
              ? this.props.silolistsStore.detail.sorted_produits
              : null
          }
          sorted_pir_pcr={
            this.props.silolistsStore.detail
              ? this.props.silolistsStore.detail.sorted_pir_pcr
              : null
          }
          maxWeight={POIDS_ENTREE_MAX}
          sesotec={true}
          sesotec_cb={this.sesotecCb.bind(this)}
        />
        <Grid item xs={12} className={classes.divider}>
          <Divider />
        </Grid>
        <ProduitTransforme
          title="Produits en sortie"
          columns={[
            "reference",
            "nom_famille",
            "article_nom",
            "quantite",
            "poids",
            "separateur_metaux",
            "commentaire",
            "commentaire_interne",
          ]}
          datas={produits_sortie}
          dateTransformation={
            this.props.silolistsStore.detail
              ? this.props.silolistsStore.detail.date_fin
              : null
          }
          showListButtons={authorizeProduction()}
          produits_origines_code={ORIGINES_PREFIX.SILO}
          entameCode={ORIGINES_PREFIX.ENTAME}
          incompletCode={ORIGINES_PREFIX.SILO_INCOMPLET}
          famille_uuid={famille_uuid ? famille_uuid : null}
          article_uuid={article_uuid ? article_uuid : null}
          getReference={this.getReferenceProduitSortie.bind(this)}
          pagination={false}
          search={true}
          actionsCallback={this.handleChangeProduitsSortie.bind(this)}
          createUpdateModalGetNextState={this.getNextStateProduitSortie.bind(
            this
          )}
          lastProduitSortie={lastProduitSortie}
          sortieSilolist={true}
        />
        <Grid item xs={12} className={classes.balance}>
          <Typography variant="body2">
            {countComplet} produit{countComplet > 1 ? "s" : ""} plein
            {countComplet > 1 ? "s" : ""}&nbsp;({sumPoidsComplet}&nbsp;kg)
            &amp;&nbsp;
            {countIncomplet} produit{countIncomplet > 1 ? "s" : ""} incomplet
            {countIncomplet > 1 ? "s" : ""}&nbsp;({sumPoidsIncomplet}&nbsp;kg)
          </Typography>
          <Typography variant="body2">
            Poids total en entrée&nbsp;: {sumPoidsEntree}&nbsp;kg &rarr; Poids
            total en sortie&nbsp;: {sumPoidsSortie}&nbsp;kg &rArr;
            <span className={deltaPoids > 0 ? classes.unbalanced : ""}>
              &nbsp;Perte en silotage&nbsp;: {deltaFormatter.format(deltaPoids)}
              &nbsp;kg
              {sumPoidsEntree > 0
                ? ` (${percentFormatter.format(percentPoids)})`
                : ""}
            </span>
          </Typography>
        </Grid>
      </Grid>
    );
  }
  sesotecCb(produit, sesotec) {
    if (sesotec) {
      this.state.produits_entree_sesotec.push(produit.uuid);
    } else {
      /* eslint-disable-next-line */
      this.state.produits_entree_sesotec =
        this.state.produits_entree_sesotec.filter((p) => p !== produit.uuid);
    }
  }

  // Mise en place des filtres
  loadAsyncData(filters) {
    const { dispatch, silolistsStore } = this.props;

    if (silolistsStore.fetching) return;

    const { filter_status } = this.state;

    let local_filter = {
      params: {
        filter_status,
        ...filters,
      },
    };

    return collectionActions(
      dispatch,
      "silolists",
      "INDEX",
      local_filter,
      null,
      false
    );
  }

  getFilters() {
    const { classes } = this.props;
    const { filter_status } = this.state;

    let options = Object.keys(STATUT).map((key) => {
      return <MenuItem value={STATUT[key]}>{key}</MenuItem>;
    });

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            key="typo"
            variant="button"
            gutterBottom
            className={classes.crudTitle}
          >
            Filtres
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.multiSelectContainer}>
          <InputLabel id="filter_status" className={classes.filterStatusLabel}>
            STATUTS
          </InputLabel>
          <Select
            labelId="filter_status"
            label="STATUT"
            value={filter_status}
            input={<Input id="select-multiple-type" />}
            margin="normal"
            onChange={this.onChangeHandlerStatusFilters.bind(
              this,
              "filter_status"
            )}
            className={classes.selectContainer}
            select
            multiple
          >
            {options}
          </Select>
        </Grid>
      </Grid>
    );
  }

  onChangeHandlerStatusFilters(name, event) {
    let values = event.target.value;

    this.setState({ [name]: values }, () => {
      storeToLocalStorage(
        this.state.local_storage_key,
        {
          filter_status: this.state.filter_status,
        },
        60 * 12
      );
      // eslint-disable-next-line
      this.state.current_filter.page = 1; // On force la page 1
      this.loadAsyncData(this.state.current_filter, true);
    });
  }

  getDetailPerFamille() {
    const { classes } = this.props;
    const { detail_per_famille } = this.state;
    const result = [];
    if (detail_per_famille) {
      Object.keys(detail_per_famille).forEach((key) => {
        result.push(
          <div key={key} className={classes.detail_per_famille}>
            {detail_per_famille[key].libelle}{" "}
            {detail_per_famille[key].produits.length} BB,{" "}
            {detail_per_famille[key].humain_total_poids_produits},{" "}
            {detail_per_famille[key].pourcentage_poids_produits}%
          </div>
        );
      });
    }
    return result;
  }

  getDetailPirPcr() {
    const { classes } = this.props;
    const { sorted_pir_pcr } = this.state;
    const result = [];
    if (sorted_pir_pcr) {
      Object.keys(sorted_pir_pcr).map((key) => {
        result.push(
          <div key={key} className={classes.sorted_pir_pcr}>
            {sorted_pir_pcr[key].libelle} : {sorted_pir_pcr[key].pourcentage}%
          </div>
        );
      });
    }
    return result;
  }

  getCrudTitle() {
    const { classes } = this.props;
    return [
      <Typography
        key="typo"
        variant="button"
        gutterBottom
        className={classes.crudTitle}
      >
        Silo lists
      </Typography>,
    ];
  }

  getDate(string) {
    if (!string) {
      return "";
    }
    let date = new Date(string);
    let options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("fr-FR", options);
  }

  getDeleteModalTitle() {
    return (
      <div>
        <Person />
        <span>{"Suppression de la silo list"}</span>
      </div>
    );
  }

  getDeleteModalContent() {
    return (
      <Typography>
        {"Êtes-vous sûr de vouloir supprimer cette silo list ?"}
      </Typography>
    );
  }

  getDownloadButton(silolist) {
    const { classes } = this.props;
    return (
      <IconButton
        key={silolist.uuid}
        title={"Télécharger la silo list"}
        variant="contained"
        color="primary"
        href={
          process.env.REACT_APP_API_URL + "/silolists/" + silolist.uuid + "/pdf"
        }
        className={classes.button}
        target="_blank"
      >
        <VerticalAlignBottom />
      </IconButton>
    );
  }

  getFamilles() {
    if (
      !this.props.famillesStore.list ||
      this.props.articlesStore.list === null
    ) {
      return [];
    }

    return this.props.famillesStore.list.map((famille) => {
      return (
        <MenuItem
          value={famille.uuid}
          key={famille.uuid}
          disabled={
            !this.props.articlesStore.list.find(
              (a) => a.famille_uuid === famille.uuid
            )
          }
        >
          {famille.nom}
        </MenuItem>
      );
    });
  }

  getSesotecOptions() {
    return [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(
      (pourcentage, index) => {
        return (
          <MenuItem value={pourcentage} key={pourcentage + index}>
            {pourcentage + " %"}
          </MenuItem>
        );
      }
    );
  }

  /**
   * Retourne les modifications de state du nouveau produit en sortie lorsqu'il
   * est ajouté par duplication du produit précédent.
   */
  getNextStateProduitSortie(detail) {
    return {
      article_uuid: detail.article_uuid,
      commentaire: detail.commentaire,
      commentaire_interne: detail.commentaire_interne,
      date_transformation: detail.date_transformation,
      poids: 0, // poids réinitialisé
      reference: this.getReferenceProduitSortie(), // référence suivante
      separateur_metaux: detail.separateur_metaux,
      uuid: null, // pas d'identifiant en base (création),
      pollution: detail.pollution,
      taux_pollution: detail.taux_pollution,
      poussiere: detail.poussiere,
      taille_grille: detail.taille_grille,
      humidite: detail.humidite,
      mfi: detail.mfi,
      entame: false,
      imcomplet: false,
    };
  }

  getReferenceProduitSortie() {
    const { produits_sortie } = this.state;
    const response = getProduitTransformeReference(this.props.dispatch, null, {
      "sl-reference": true,
      nb_sorties: produits_sortie.length,
      silolist_uuid: this.props.silolistsStore.detail
        ? this.props.silolistsStore.detail.uuid
        : null,
    });

    return response;
  }

  getStatutsList() {
    const { silolistsStore } = this.props;
    const { statuts } = this.state;
    if (!statuts) {
      return [];
    }

    const authorizeStatut = (from, to) => {
      switch (from) {
        case STATUT.INITIE:
          switch (to) {
            case STATUT.INITIE:
            case STATUT.A_SILOTER:
              return this.hasRight("admin-silolists-creation");
            case STATUT.SILOTAGE_EN_COURS:
            case STATUT.OT_FINI:
            default:
              return this.isAdminOrSuperAdmin();
          }
        case STATUT.A_SILOTER:
          switch (to) {
            case STATUT.INITIE:
            case STATUT.A_SILOTER:
              return this.hasRight("admin-silolists-creation");
            case STATUT.SILOTAGE_EN_COURS:
              return this.hasRight("admin-silolists-production");
            case STATUT.OT_FINI:
            default:
              return this.isAdminOrSuperAdmin();
          }
        case STATUT.SILOTAGE_EN_COURS:
          switch (to) {
            case STATUT.INITIE:
              return this.isAdminOrSuperAdmin();
            case STATUT.A_SILOTER:
            case STATUT.SILOTAGE_EN_COURS:
            case STATUT.OT_FINI:
              return this.hasRight("admin-silolists-production");
            default:
              return this.isAdminOrSuperAdmin();
          }
        case STATUT.OT_FINI:
        default:
          return this.isAdminOrSuperAdmin();
      }
    };

    const statutInitial = silolistsStore.detail
      ? silolistsStore.detail.statut
      : null;
    return Object.keys(statuts).map((key) => (
      <MenuItem
        key={key}
        disabled={!authorizeStatut(statutInitial, key)}
        value={key}
      >
        {statuts[key]}
      </MenuItem>
    ));
  }

  getUserLead(silolist) {
    const { users } = this.state;
    if (users && silolist.user_uuids.length > 0) {
      const filtered = users.filter((user) =>
        silolist.user_uuids.includes(user.uuid)
      );
      const first = filtered.length > 0 ? filtered[0] : null;
      const ellipsis = filtered.length > 1 ? "..." : "";
      return first ? `${first.firstname} ${first.lastname}${ellipsis}` : "";
    }
    return null;
  }

  handleChangeProduitsSortie(action, produit) {
    const { produits_sortie } = this.state;

    // Associe à la silolist le nouveau produit en sortie
    if (action === "create") {
      this.setState({ produits_sortie: [...produits_sortie, produit] }, () => {
        // Demande client : enrigster la SL automatiquement à la création d'un BB
        // Julien : OK si on est en modification d'un SL existante, mais pas lors d'une création
        if (this.state.silolist.uuid) {
          this.submitHandlerCreateUpdate({}, () => {});
        }
      });
    }

    // Dissocie de la silolist le produit en sortie supprimé
    if (action === "delete") {
      this.setState({
        produits_sortie: produits_sortie.filter(
          (item) => item.uuid !== produit.uuid
        ),
      });
    }

    // Force le rafraîchissement de la liste des produits en sortie
    if (action === "update") {
      delete produit.silolist; // désactive le style réservé
      this.setState({
        produits_sortie: [
          ...produits_sortie.filter((item) => item.uuid !== produit.uuid),
          produit,
        ],
      });
    }

    if (action === "create" || action === "delete" || action === "update") {
      if (action === "create" || action === "update") {
        this.setState({
          lastProduitSortie: this.getNextStateProduitSortie(produit),
        });
      }
      this.handleChangeProduits();
    }
  }

  handleChangeProduits() {
    const { dispatch } = this.props;
    const { produits_entree, produits_sortie } = this.state;
    const sumPoids = (cumulated, item) => cumulated + parseInt(item.poids);
    const sumPoidsEntree = produits_entree.reduce(sumPoids, 0);

    if (sumPoidsEntree >= POIDS_ENTREE_MAX) {
      addNotification(dispatch, {
        message: "Poids total en entrée supérieur à 13 tonnes",
        bgColor: themeComplement.palette.notifications.warning.color,
      });
    }

    /**
     * Mise à jour du détail par famille
     */
    let detail_per_famille = {};
    produits_entree.forEach((produit) => {
      const key = produit.nom_famille + " / " + produit.article_nom;
      if (!detail_per_famille[key]) {
        detail_per_famille[key] = {
          libelle: produit.nom_famille + " " + produit.article_nom,
          produits: [],
          total_poids_produits: 0,
          pourcentage_poids_produits: 0,
          humain_total_poids_produits: "",
        };
      }
      detail_per_famille[key].produits.push(produit);
    });
    Object.keys(detail_per_famille).forEach((key) => {
      detail_per_famille[key].total_poids_produits = detail_per_famille[
        key
      ].produits.reduce(sumPoids, 0);
      detail_per_famille[key].pourcentage_poids_produits = (
        (detail_per_famille[key].total_poids_produits / sumPoidsEntree) *
        100
      ).toFixed(2);
      const total_tonne = (detail_per_famille[key].total_poids_produits / 1000)
        .toFixed(3)
        .split(".");
      detail_per_famille[key].humain_total_poids_produits =
        total_tonne[0] + "T" + total_tonne[1];
    });
    this.setState({ detail_per_famille });

    /**
     * Mise à jour du détail Pir/Pcr
     */
    let sorted_pir_pcr = {};
    let poids_total = 0;
    produits_entree.forEach((produit) => {

      const key = produit.pir_pcr_libelle;
      if (!sorted_pir_pcr[key]) {
        sorted_pir_pcr[key] = {
          libelle: produit.pir_pcr_libelle ?? "non renseigné",
          poids: 0,
          pourcentage: 0,
        };
      }
      poids_total += parseInt(produit.poids);

      sorted_pir_pcr[key].poids += parseInt(produit.poids);
    });
// on met à jour le pourcentage
    Object.keys(sorted_pir_pcr).map(
      (key) =>
        (sorted_pir_pcr[key].pourcentage =
          parseInt(sorted_pir_pcr[key].poids) > 0
            ? (
                (parseInt(sorted_pir_pcr[key].poids) / poids_total) *
                100
              ).toFixed(2)
            : 0)
    );

    this.setState({ sorted_pir_pcr });

    if (produits_sortie.length === 0) {
      return;
    }
    const sumPoidsSortie = produits_sortie.reduce(sumPoids, 0);
    const deltaPoids = sumPoidsSortie - sumPoidsEntree;
    if (deltaPoids > 0) {
      addNotification(dispatch, {
        message: "Poids total en sortie supérieur au poids total en entrée",
        bgColor: themeComplement.palette.notifications.warning.color,
      });
    }
  }

  handleChangeUser(uuids) {
    this.setState({
      silolist: {
        ...this.state.silolist,
        user_uuids: uuids,
      },
    });
  }

  handleDeleteUser(uuid) {
    const { user_uuids } = this.state.silolist;
    const index = user_uuids.findIndex((item) => item.uuid === uuid);
    if (index !== -1) {
      let array = [...user_uuids];
      array.splice(index, 1);
      this.setState({
        silolist: {
          ...this.state.silolist,
          user_uuids: array,
        },
      });
    }
  }

  hasRight(right) {
    const { user } = this.props;
    return this.isAdminOrSuperAdmin() || hasRights(right, user);
  }

  isAdminOrSuperAdmin() {
    const { user } = this.props;
    return hasRights("silot-list-retour-ot", user) || user.is_admin;
  }

  onArticleChangeHandler(name, event) {
    const changed = {
      [name]: event.target.value,
    };
    // eslint-disable-next-line default-case
    switch (name) {
      // eslint-disable-next-line no-fallthrough
      case "famille_uuid":
        changed.article_uuid = null; // doesn't work: article_uuid not in state
    }
    this.setState(changed);
  }

  onClickSelectProduitHandler(produit) {
    const { produits_entree } = this.state;
    if (
      produits_entree.findIndex((item) => item.uuid === produit.uuid) === -1
    ) {
      this.setState(
        { produits_entree: [...produits_entree, produit].sort(this.compare) },
        () => this.handleChangeProduits()
      );
    } else {
      this.setState(
        {
          produits_entree: produits_entree.filter(
            (item) => item.uuid !== produit.uuid
          ),
        },
        () => this.handleChangeProduits()
      );
    }
  }

  onClickRemoveProduitHandler(produit) {
    const { produits_entree } = this.state;
    if (
      produits_entree.findIndex((item) => item.uuid === produit.uuid) !== -1
    ) {
      this.setState(
        {
          produits_entree: produits_entree.filter(
            (item) => item.uuid !== produit.uuid
          ),
        },
        () => this.handleChangeProduits()
      );
    }
  }

  actionsCallback(action, silolist) {
    const { dispatch } = this.props;
    switch (action) {
      case "close":
        // Réinitialise le state
        this.setState({
          famille_uuid: null,
          produits_entree: [],
          produits_sortie: [],
          produits_entree_sesotec: [],
          selectedSilolist: null,
          silolist: {},
          detail_per_famille: {},
          sorted_pir_pcr: {},
        });
        collectionActions(dispatch, "silolists", "RESET_DETAIL");
        break;
      case "click-add":
      case "click-edit":
        const { silolistsStore } = this.props;
        let famille_uuid = null;

        let produits_entree = [];
        let produits_sortie = [];
        let detail_per_famille = {};
        let sorted_pir_pcr = {};
        let produits_entree_sesotec = [];

        let silolistLocal = {
          statut: STATUT.INITIE,
        };

        if (silolist) {
          loadCollectionAttribute(
            dispatch,
            "detail",
            "silolists",
            silolistsStore,
            {
              uuid: silolist.uuid,
            },
            false,
            (silolistFromStore) => {
              if (silolistFromStore) {
                famille_uuid = silolistFromStore.article.famille_uuid;
                produits_entree = silolistFromStore.produits_entree;
                produits_sortie = silolistFromStore.produits_sortie;
                silolistLocal = silolistFromStore;
                detail_per_famille = silolistFromStore.sorted_produits;
                sorted_pir_pcr = silolistFromStore.sorted_pir_pcr;
                produits_entree_sesotec = silolistFromStore.produits_entree
                  .filter((p) => p.pivot && p.pivot.sesotec)
                  .map((p) => p.uuid);

                this.setState({
                  produits_entree,
                  produits_sortie,
                  famille_uuid,
                  silolist: silolistLocal,
                  detail_per_famille,
                  sorted_pir_pcr,
                  produits_entree_sesotec,
                });
              }
            }
          );
        }

        if (!silolist) {
          this.setState({
            produits_entree,
            produits_sortie,
            famille_uuid,
            silolist: silolistLocal,
            detail_per_famille,
            sorted_pir_pcr,
            produits_entree_sesotec,
          });
        }
        break;
      default:
        break;
    }
  }

  submitHandlerCreateUpdate(detail, closeCallback) {
    const { dispatch } = this.props;
    collectionActions(
      dispatch,
      "silolists",
      this.state.silolist.uuid ? "UPDATE" : "CREATE",
      {
        ...this.state.silolist,
        produits_entree: this.state.produits_entree.map(
          (produit) => produit.uuid
        ),
        produits_sortie: this.state.produits_sortie.map(
          (produit) => produit.uuid
        ),
        produits_entree_sesotec: this.state.produits_entree_sesotec,
      },
      (detail) => {
        if (detail) {
          closeCallback();
        }
      }
    );
  }

  render() {
    const { datas } = this.props;
    if (!this.state.statuts) {
      return <CircularProgress size={28} />;
    }
    return (
      <>
        {this.getFilters()}
        <CollectionCrud
          fullScreenModal
          modalMaxWidth="lg"
          collectionName={"silolists"}
          showBtnAdd={this.hasRight("admin-silolists-creation")}
          showBtnEdit={true}
          showBtnDelete={true}
          showBtnDeleteCallBack={this.showBtnDelete.bind(this)}
          additionnalControllers={[this.getDownloadButton.bind(this)]}
          actionsCallback={this.actionsCallback.bind(this)}
          asynchroneCallbackLoadData={this.loadAsyncData.bind(this)}
          getAsyncFilter={(filter) =>
            // eslint-disable-next-line
            (this.state.current_filter = filter)
          }
          datas={datas}
          loadDatas={!!datas ? false : true}
          deleteModalTitle={this.getDeleteModalTitle}
          deleteModalContent={this.getDeleteModalContent.bind(this)}
          createUpdateModalTitle={this.getCreateUpdateModalTitle}
          createUpdateModalContent={this.getCreateUpdateModalContent.bind(this)}
          datatableConfig={{
            showPagination: true,
            showSearch: true,
            defaultSort: "reference",
            sortType: "asc",
            nodatalabel: "Aucune silolist",
            rowsPerPageOptions: [50, 100],
          }}
          dataTableExtraNodes={[
            {
              element: this.getCrudTitle(),
              position: "top-left",
            },
          ]}
          cellsConfig={this.getCellsConfig()}
          defaultValues={{
            statut: STATUT.INITIE,
          }}
          submitHandlerCreateUpdate={this.submitHandlerCreateUpdate.bind(this)}
        />
      </>
    );
  }
}

Silolist = withStyles(SilolistCss)(Silolist);

Silolist = connect((store) => ({
  articlesStore: store.collections.articles,
  famillesStore: store.collections.familles,
  silolistsStore: store.collections.silolists,
  user: store.auth.user,
}))(Silolist);

export default Silolist;
export { REF_PREFIX, Silolist };
